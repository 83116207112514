/**
 * Retona a lista de DDI de todos os países
 * @returns Object
 */
export const DDIList = (value) => {
  const list = [
    { id: 1, ddi: 93, country: "Afeganistão" },
    { id: 2, ddi: 27, country: "África do Sul" },
    { id: 3, ddi: 355, country: "Albânia" },
    { id: 4, ddi: 49, country: "Alemanha" },
    { id: 5, ddi: 376, country: "Andorra" },
    { id: 6, ddi: 244, country: "Angola" },
    { id: 7, ddi: 1, country: "Anguilla" },
    { id: 8, ddi: 1, country: "Antígua e Barbuda" },
    { id: 9, ddi: 599, country: "Antilhas Holandesas" },
    { id: 10, ddi: 966, country: "Arábia Saudita" },
    { id: 11, ddi: 213, country: "Argélia" },
    { id: 12, ddi: 54, country: "Argentina" },
    { id: 13, ddi: 374, country: "Armênia" },
    { id: 14, ddi: 297, country: "Aruba" },
    { id: 15, ddi: 247, country: "Ascensão" },
    { id: 16, ddi: 61, country: "Austrália" },
    { id: 17, ddi: 43, country: "Áustria" },
    { id: 18, ddi: 994, country: "Azerbaidjão" },
    { id: 19, ddi: 1, country: "Bahamas" },
    { id: 20, ddi: 880, country: "Bangladesh" },
    { id: 21, ddi: 1, country: "Barbados" },
    { id: 22, ddi: 973, country: "Barein" },
    { id: 23, ddi: 32, country: "Bélgica" },
    { id: 24, ddi: 501, country: "Belize" },
    { id: 25, ddi: 229, country: "Benim" },
    { id: 26, ddi: 1, country: "Bermudas" },
    { id: 27, ddi: 375, country: "Bielorrússia" },
    { id: 28, ddi: 55, country: "Brasil" },
    { id: 29, ddi: 591, country: "Bolívia" },
    { id: 30, ddi: 387, country: "Bósnia e Herzegovina" },
    { id: 31, ddi: 267, country: "Botswana" },
    { id: 32, ddi: 673, country: "Brunei" },
    { id: 33, ddi: 359, country: "Bulgária" },
    { id: 34, ddi: 226, country: "Burkina Faso" },
    { id: 35, ddi: 257, country: "Burundi" },
    { id: 36, ddi: 975, country: "Butão" },
    { id: 37, ddi: 238, country: "Cabo Verde" },
    { id: 38, ddi: 237, country: "Camarões" },
    { id: 39, ddi: 855, country: "Camboja" },
    { id: 40, ddi: 1, country: "Canadá" },
    { id: 41, ddi: 7, country: "Cazaquistão" },
    { id: 42, ddi: 237, country: "Chade" },
    { id: 43, ddi: 56, country: "Chile" },
    { id: 44, ddi: 357, country: "Chipre" },
    { id: 45, ddi: 57, country: "Colômbia" },
    { id: 46, ddi: 269, country: "Comores" },
    { id: 47, ddi: 242, country: "Congo-Brazzaville" },
    { id: 48, ddi: 243, country: "Congo-Kinshasa" },
    { id: 49, ddi: 850, country: "Coreia do Norte" },
    { id: 50, ddi: 82, country: "Coreia do Sul" },
    { id: 51, ddi: 225, country: "Costa do Marfim" },
    { id: 52, ddi: 506, country: "Costa Rica" },
    { id: 53, ddi: 385, country: "Croácia" },
    { id: 54, ddi: 53, country: "Cuba" },
    { id: 55, ddi: 45, country: "Dinamarca" },
    { id: 56, ddi: 253, country: "Djibuti" },
    { id: 57, ddi: 1, country: "Dominica" },
    { id: 58, ddi: 20, country: "Egito" },
    { id: 59, ddi: 503, country: "El Salvador" },
    { id: 60, ddi: 971, country: "Emirados Árabes Unidos" },
    { id: 61, ddi: 593, country: "Equador" },
    { id: 62, ddi: 291, country: "Eritreia" },
    { id: 63, ddi: 421, country: "Eslováquia" },
    { id: 64, ddi: 386, country: "Eslovênia" },
    { id: 65, ddi: 34, country: "Espanha" },
    { id: 66, ddi: 691, country: "Estados Federados da Micronésia" },
    { id: 67, ddi: 1, country: "Estados Unidos" },
    { id: 68, ddi: 372, country: "Estonia" },
    { id: 69, ddi: 251, country: "Etiópia" },
    { id: 70, ddi: 679, country: "Fiji" },
    { id: 71, ddi: 63, country: "Filipinas" },
    { id: 72, ddi: 358, country: "Finlândia" },
    { id: 73, ddi: 33, country: "França" },
    { id: 74, ddi: 241, country: "Gabão" },
    { id: 75, ddi: 220, country: "Gambia" },
    { id: 76, ddi: 233, country: "Gana" },
    { id: 77, ddi: 995, country: "Geórgia" },
    { id: 78, ddi: 350, country: "Gibraltar" },
    { id: 79, ddi: 1, country: "Granada" },
    { id: 80, ddi: 30, country: "Grécia" },
    { id: 81, ddi: 299, country: "Groenlândia" },
    { id: 82, ddi: 590, country: "Guadalupe" },
    { id: 83, ddi: 671, country: "Guam" },
    { id: 84, ddi: 502, country: "Guatemala" },
    { id: 85, ddi: 592, country: "Guiana" },
    { id: 86, ddi: 594, country: "Guiana Francesa" },
    { id: 87, ddi: 224, country: "Guiné" },
    { id: 88, ddi: 245, country: "Guiné-Bissau" },
    { id: 89, ddi: 240, country: "Guiné Equatorial" },
    { id: 90, ddi: 509, country: "Haiti" },
    { id: 91, ddi: 504, country: "Honduras" },
    { id: 92, ddi: 852, country: "Hong Kong" },
    { id: 93, ddi: 36, country: "Hungria" },
    { id: 94, ddi: 967, country: "Iêmen" },
    { id: 95, ddi: 1, country: "Ilhas Cayman" },
    { id: 96, ddi: 672, country: "Ilha Christmas" },
    { id: 97, ddi: 672, country: "Ilhas Cocos" },
    { id: 98, ddi: 682, country: "Ilhas Cook" },
    { id: 99, ddi: 298, country: "Ilhas Féroe" },
    { id: 100, ddi: 672, country: "Ilha Heard e Ilhas McDonald" },
    { id: 101, ddi: 960, country: "Maldivas" },
    { id: 102, ddi: 500, country: "Ilhas Malvinas" },
    { id: 103, ddi: 1, country: "Ilhas Marianas do Norte" },
    { id: 104, ddi: 692, country: "Ilhas Marshall" },
    { id: 105, ddi: 672, country: "Ilha Norfolk" },
    { id: 106, ddi: 677, country: "Ilhas Salomão" },
    { id: 107, ddi: 1, country: "Ilhas Virgens Americanas" },
    { id: 108, ddi: 1, country: "Ilhas Virgens Britânicas" },
    { id: 109, ddi: 91, country: "Índia" },
    { id: 110, ddi: 62, country: "Indonésia" },
    { id: 111, ddi: 98, country: "Irã" },
    { id: 112, ddi: 964, country: "Iraque" },
    { id: 113, ddi: 353, country: "Irlanda" },
    { id: 114, ddi: 354, country: "Islândia" },
    { id: 115, ddi: 972, country: "Israel" },
    { id: 116, ddi: 39, country: "Itália" },
    { id: 117, ddi: 1, country: "Jamaica" },
    { id: 118, ddi: 81, country: "Japão" },
    { id: 119, ddi: 962, country: "Jordânia" },
    { id: 120, ddi: 686, country: "Kiribati" },
    { id: 121, ddi: 383, country: "Kosovo" },
    { id: 122, ddi: 965, country: "Kuwait" },
    { id: 123, ddi: 856, country: "Laos" },
    { id: 124, ddi: 266, country: "Lesoto" },
    { id: 125, ddi: 371, country: "Letônia" },
    { id: 126, ddi: 961, country: "Líbano" },
    { id: 127, ddi: 231, country: "Libéria" },
    { id: 128, ddi: 218, country: "Líbia" },
    { id: 129, ddi: 423, country: "Liechtenstein" },
    { id: 130, ddi: 370, country: "Lituânia" },
    { id: 131, ddi: 352, country: "Luxemburgo" },
    { id: 132, ddi: 853, country: "Macau" },
    { id: 133, ddi: 261, country: "Madagascar" },
    { id: 134, ddi: 60, country: "Malásia" },
    { id: 135, ddi: 265, country: "Malawi" },
    { id: 136, ddi: 223, country: "Mali" },
    { id: 137, ddi: 356, country: "Malta" },
    { id: 138, ddi: 212, country: "Marrocos" },
    { id: 139, ddi: 596, country: "Martinica" },
    { id: 140, ddi: 230, country: "Maurícia" },
    { id: 141, ddi: 222, country: "Mauritânia" },
    { id: 142, ddi: 269, country: "Mayotte" },
    { id: 143, ddi: 52, country: "México" },
    { id: 144, ddi: 258, country: "Moçambique" },
    { id: 145, ddi: 373, country: "Moldávia" },
    { id: 146, ddi: 377, country: "Mônaco" },
    { id: 147, ddi: 976, country: "Mongólia" },
    { id: 148, ddi: 382, country: "Montenegro" },
    { id: 149, ddi: 1, country: "Montserrat" },
    { id: 150, ddi: 95, country: "Myanmar" },
    { id: 151, ddi: 264, country: "Namíbia" },
    { id: 152, ddi: 674, country: "Nauru" },
    { id: 153, ddi: 977, country: "Nepal" },
    { id: 154, ddi: 505, country: "Nicarágua" },
    { id: 155, ddi: 227, country: "Níger" },
    { id: 156, ddi: 234, country: "Nigéria" },
    { id: 157, ddi: 683, country: "Niue" },
    { id: 158, ddi: 47, country: "Noruega" },
    { id: 159, ddi: 687, country: "Nova Caledônia" },
    { id: 160, ddi: 64, country: "Nova Zelândia" },
    { id: 161, ddi: 968, country: "Omã" },
    { id: 162, ddi: 31, country: "Países Baixos" },
    { id: 163, ddi: 680, country: "Palau" },
    { id: 164, ddi: 970, country: "Palestina" },
    { id: 165, ddi: 507, country: "Panamá" },
    { id: 166, ddi: 675, country: "Papua-Nova Guiné" },
    { id: 167, ddi: 92, country: "Paquistão" },
    { id: 168, ddi: 595, country: "Paraguai" },
    { id: 169, ddi: 51, country: "Peru" },
    { id: 170, ddi: 689, country: "Polinésia Francesa" },
    { id: 171, ddi: 48, country: "Polônia" },
    { id: 172, ddi: 1, country: "Porto Rico" },
    { id: 173, ddi: 351, country: "Portugal" },
    { id: 174, ddi: 974, country: "Qatar" },
    { id: 175, ddi: 254, country: "Quênia" },
    { id: 176, ddi: 996, country: "Quirguistão" },
    { id: 177, ddi: 44, country: "Reino Unido" },
    { id: 178, ddi: 236, country: "República Centro-Africana" },
    { id: 179, ddi: 886, country: "República da China" },
    { id: 180, ddi: 86, country: "República Popular da China" },
    { id: 181, ddi: 1, country: "República Dominicana" },
    { id: 182, ddi: 389, country: "República da Macedônia" },
    { id: 183, ddi: 420, country: "República Tcheca" },
    { id: 184, ddi: 262, country: "Reunião" },
    { id: 185, ddi: 40, country: "Romênia" },
    { id: 186, ddi: 250, country: "Ruanda" },
    { id: 187, ddi: 7, country: "Rússia" },
    { id: 188, ddi: 212, country: "Saara Ocidental" },
    { id: 189, ddi: 685, country: "Samoa" },
    { id: 190, ddi: 1, country: "amoa Americana" },
    { id: 191, ddi: 290, country: "Santa Helena (território)" },
    { id: 192, ddi: 1, country: "Santa Lúcia" },
    { id: 193, ddi: 1, country: "São Cristóvão e Nevis" },
    { id: 194, ddi: 378, country: "São Marinho" },
    { id: 195, ddi: 508, country: "Saint-Pierre e Miquelon" },
    { id: 196, ddi: 239, country: "São Tomé e Príncipe" },
    { id: 197, ddi: 1, country: "São Vicente e Granadinas" },
    { id: 198, ddi: 248, country: "Seicheles" },
    { id: 199, ddi: 221, country: "Senegal" },
    { id: 200, ddi: 232, country: "Serra Leoa" },
    { id: 201, ddi: 381, country: "Sérvia" },
    { id: 202, ddi: 65, country: "Singapura" },
    { id: 203, ddi: 963, country: "Síria" },
    { id: 204, ddi: 252, country: "Somália" },
    { id: 205, ddi: 94, country: "Sri Lanka" },
    { id: 206, ddi: 268, country: "Suazilândia" },
    { id: 207, ddi: 249, country: "Sudão" },
    { id: 208, ddi: 211, country: "Sudão do Sul" },
    { id: 209, ddi: 46, country: "Suécia" },
    { id: 210, ddi: 41, country: "Suíça" },
    { id: 211, ddi: 597, country: "Suriname" },
    { id: 212, ddi: 992, country: "Tadjiquistão" },
    { id: 213, ddi: 66, country: "Tailândia" },
    { id: 214, ddi: 255, country: "Tanzânia" },
    { id: 215, ddi: 246, country: "Território Britânico do Oceano Índico" },
    { id: 216, ddi: 670, country: "Timor-Leste" },
    { id: 217, ddi: 228, country: "Togo" },
    { id: 218, ddi: 690, country: "Tokelau" },
    { id: 219, ddi: 676, country: "Tonga" },
    { id: 220, ddi: 1, country: "Trinidad e Tobago" },
    { id: 221, ddi: 216, country: "Tunísia" },
    { id: 222, ddi: 1, country: "Turcas e Caicos" },
    { id: 223, ddi: 993, country: "Turquemenistão" },
    { id: 224, ddi: 90, country: "Turquia" },
    { id: 225, ddi: 688, country: "Tuvalu" },
    { id: 226, ddi: 380, country: "Ucrânia" },
    { id: 227, ddi: 256, country: "Uganda" },
    { id: 228, ddi: 598, country: "Uruguai" },
    { id: 229, ddi: 998, country: "Uzbequistão" },
    { id: 230, ddi: 678, country: "Vanuatu" },
    { id: 231, ddi: 379, country: "Vaticano" },
    { id: 232, ddi: 58, country: "Venezuela" },
    { id: 233, ddi: 84, country: "Vietnã" },
    { id: 234, ddi: 681, country: "Wallis e Futuna" },
    { id: 235, ddi: 260, country: "Zâmbia" },
    { id: 236, ddi: 263, country: "Zimbábue" },
  ];

  if (value && value !== 0) {
    return list.filter((item) => item.ddi === value)[0];
  }

  return list;
};
