import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//redux
import {
  errors,
  userResponses,
} from "../../../../../store/survey/survey.slice";

//styles
import "../../../../../assets/css/survey/input.css";

//utils
import { getStorage, setStorage } from "../../../../../utils/Storage";
import { DDIList } from "../../../../../utils/Object";

const InputPhoneComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const listError = useSelector(errors);
  const userSurvey = useSelector(userResponses);
  const [localInput, setLocalInput] = useState({
    id: 28,
    ddi: 55,
    country: "Brasil",
    number: "",
  });

  const PhoneMask = (value) => {
    let clearValue = value
      .replaceAll(" ", "")
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll(" ", "")
      .replaceAll("-", "");

    let rx = new RegExp(/^[0-9]*$/gm);

    if (localInput.ddi === 55) {
      if (rx.exec(clearValue)) {
        let valueExtract = clearValue.split("");
        let valueTracted = [];

        for (let i = 0; i <= 10; i++) {
          if (i === 0) {
            if (valueExtract[i]) {
              valueTracted.push("(" + valueExtract[i]);
            }
          } else if (i === 2) {
            if (valueExtract[i]) {
              valueTracted.push(") " + valueExtract[i]);
            }
          } else if (i === 7) {
            if (valueExtract[i]) {
              valueTracted.push("-" + valueExtract[i]);
            }
          } else {
            if (valueExtract[i]) {
              valueTracted.push(valueExtract[i]);
            }
          }
        }

        const valueFormatted = valueTracted.join("");
        return valueFormatted;
      }
    } else {
      if (rx.exec(clearValue)) {
        let valueExtract = clearValue.split("").reverse();
        let valueTracted = [];

        for (let i = 0; i <= 10; i++) {
          if (i === 3 || i === 6) {
            if (valueExtract[i]) {
              valueTracted.push(" " + valueExtract[i]);
            }
          } else {
            if (valueExtract[i]) {
              valueTracted.push(valueExtract[i]);
            }
          }
        }
        valueTracted = valueTracted.reverse();
        const valueFormatted = valueTracted.join("");
        return valueFormatted;
      }
    }

    return localInput.number;
  };

  const getObjectPhoneById = (id) => {
    id = parseInt(id);
    const result = DDIList().filter((value) => value.id === id);
    return result[0];
  };

  const changeDDI = (event) => {
    const selectedValue = getObjectPhoneById(event.target.value);
    const selectItem = {
      number: localInput.number,
      id: selectedValue.id,
      ddi: selectedValue.ddi,
      country: selectedValue.country,
    };
    let surveyData = getStorage(surveyid, true);
    surveyData.user.responses[data.id] = selectItem;
    setStorage(surveyid, surveyData, true);
    setLocalInput(selectItem);
  };

  const changeInput = (val) => {
    let surveyData = getStorage(surveyid, true);
    const valueFormatted = PhoneMask(val);
    const selectItem = {
      number: valueFormatted,
      id: localInput.id,
      ddi: localInput.ddi,
      country: localInput.country,
    };
    surveyData.user.responses[data.id] = selectItem;
    setStorage(surveyid, surveyData, true);
    setLocalInput(selectItem);
  };

  useEffect(() => {
    if (userSurvey.responses[data.id]) {
      setLocalInput(userSurvey.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div id="input-component" className={`tel ${data?.cssClass || ""}`}>
      <div className="input-question">
        <Interweave content={data.title[language]} />
      </div>
      <div className="input-content">
        <select
          id={"ddi" + data.id}
          value={localInput.id}
          onChange={(e) => changeDDI(e)}
        >
          {DDIList().map((value, index) => {
            const { ddi, country } = value;
            return (
              <option key={`ddi-${data.id}-${index}`} value={value.id}>
                {`(+${ddi}) ${country}`}
              </option>
            );
          })}
        </select>
        <input
          type="text"
          maxLength={data?.properties?.maxLength || 20}
          value={localInput.number}
          placeholder={
            localInput.ddi === 55 ? "(99) 99999-9999" : "(999) 999-9999"
          }
          onChange={({ target: { value } }) => changeInput(value)}
        />
      </div>
      <div className="input-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default InputPhoneComponent;
