import React from "react";

//styles
import "../../assets/css/components/app/content.app.css";

const ContentApp = ({children}) => {
  return (
    <div id="inovyo-content">
      {children}
    </div>
  );
};

export default ContentApp;
