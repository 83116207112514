import React from "react";
import { Routes, Route } from "react-router-dom";

//PUBLIC ROUTES
import HomePage from "../pages/General/Home";
import SurveyPage from "../pages/Survey";

//PRIVATE ROUTES

//compoenents
import LayoutApp from "../components/app/LayoutApp";

function AppRoutes() {
  return (
    <>
      <LayoutApp>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/test/:surveyid" element={<SurveyPage />}>
            <Route path=":responseid" element={<SurveyPage />} />
          </Route>
          <Route path="/preview/:surveyid" element={<SurveyPage />}>
            <Route path=":responseid" element={<SurveyPage />} />
          </Route>
          <Route path="/:surveyid" element={<SurveyPage />}>
            <Route path=":responseid" element={<SurveyPage />} />
          </Route>
          <Route path="*" element={<HomePage />} />
        </Routes>
      </LayoutApp>
    </>
  );
}

export default AppRoutes;
