import React, { createContext } from "react";

import userAuth from "./hooks/userAuth";

const Context = createContext();

function AuthProvider({ children }) {
  const { authenticated } = userAuth();

  return (
    <Context.Provider
      value={{
        authenticated,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, AuthProvider };
