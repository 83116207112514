import api from "../../services/api";

export const getSurveyAPI = async ({ surveyid, id: responseid }) => {
  return api
    .get(`/${surveyid}/${responseid}${window.location.search}`)
    .then((reponse) => {
      return reponse.data;
    });
};

export const getSurveyTestAPI = async ({ surveyid, id: responseid }) => {
  return api
    .get(`/t/${surveyid}/${responseid}${window.location.search}`)
    .then((reponse) => {
      return reponse.data;
    });
};

export const getSurveyTimerAPI = async (surveyid) => {
  return api.get(`/timer/${surveyid}/`).then((reponse) => {
    return reponse.data;
  });
};

export const receiveResponseAPI = async (data) => {
  return api.post(`/receive-response`, data).then((reponse) => {
    return reponse.data;
  });
};

export const actionDispatchAPI = async (data) => {
  return api.post("/action", data).then((reponse) => {
    return reponse.data;
  });
};
