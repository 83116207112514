import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//redux
import { errors, userResponses } from "../../../../store/survey/survey.slice";
import { putResponse } from "../../../Survey/validates";

//styles
import "../../../../assets/css/survey/textarea.css";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

const TextareaComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const listError = useSelector(errors);
  const userSurvey = useSelector(userResponses);
  const [localTextArea, setLocalTextArea] = useState("");

  const changeTextarea = (val) => {
    let surveyData = getStorage(surveyid, true);
    if (val !== "") {
      surveyData.user.responses[data.id] = val;
    } else {
      delete surveyData.user.responses[data.id];
    }
    setStorage(surveyid, surveyData, true);

    if (localTextArea.length <= data?.properties?.maxLength || 1000)
      setLocalTextArea(val);

    putResponse(surveyid);
  };

  useEffect(() => {
    if (userSurvey.responses[data.id]) {
      setLocalTextArea(userSurvey.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div id="textarea-component" className={`textarea ${data?.cssClass || ""}`}>
      <div className="textarea-question">
        <Interweave content={data.title[language]} />
      </div>
      <div className="textarea-content">
        <textarea
          cols="30"
          rows="5"
          className="textarea"
          value={localTextArea}
          maxLength={data?.properties?.maxLength || 1000}
          onChange={(e) => changeTextarea(e.target.value)}
        ></textarea>
        <div className="count-words">
          {localTextArea.length} de {data?.properties?.maxLength || 1000}{" "}
          caracteres
        </div>
      </div>
      <div className="textarea-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default TextareaComponent;
