import React from "react";

//utils
import { getLuminosity } from "../../../../utils/String";

//styles
import "../../../../assets/css/survey/button.css";

const ButtonSurvey = (props) => {
  let { children, color } = props;
  const classProp = props.className
    ? `button-survey ${props.className}`
    : "button-survey";

  const styleButton = `
    button.button-survey {
      background-color: ${color !== "" ? color : "#9a2376"};
      color: ${
        color !== ""
          ? getLuminosity(color) === "dark"
            ? "#fff"
            : "#000"
          : "#fff"
      };
    }
    button.button-survey:hover {
      background-color: ${color !== "" ? `${color}cc` : "#9a2376"}
    }
    button.button-survey:active {
      background-color: ${color !== "" ? `${color}` : "#9a2376"}
    }
  `;
  return (
    <>
      <button {...props} className={classProp}>
        {children}
      </button>
      <style>{styleButton}</style>
    </>
  );
};

export default ButtonSurvey;
