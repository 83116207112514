import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { hasQuestionValue } from "../../validates";

//redux
import { actionResponse } from "../../../../store/survey/survey.slice";
import { actionDispatch } from "../../../../store/survey/survey.actions";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

const ActionComponent = ({ data }) => {
  const { surveyid } = useParams();
  const dispatch = useDispatch();
  const resAction = useSelector(actionResponse);

  const actionAPI = (dataAction) => {
    /*
    Exemplo
    {
      "setup": "bacio",
      "type": "API",
      "url": ""
      "responses": "{}"
     } */
    dispatch(actionDispatch(dataAction));
  };

  const redirectUser = (dataAction) => {
    /*
    Exemplo
    {
      "timer": 5,
      "type": "redirect",
      "openWindow": true,
      "url": "http://ositenaoexiste.localhost"
     } */
    setTimeout(() => {
      dataAction.url = hasQuestionValue(surveyid, dataAction.url);

      if (dataAction.openWindow) {
        window.open(dataAction.url, "_blank");
      } else {
        window.location.href = dataAction.url;
      }
    }, dataAction.timer * 1000);
  };

  useEffect(() => {
    if (resAction !== null) {
      let surveyData = getStorage(surveyid, true);
      surveyData.user.responses[data.id] = resAction;
      setStorage(surveyid, surveyData, true);
    }

    // eslint-disable-next-line
  }, [resAction]);

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);
    const actionData = surveyData.survey.actions[data.id + ""];
    switch (actionData.type) {
      case "API":
        actionData.responses = surveyData.user;
        actionAPI(actionData);
        break;
      case "redirect":
        redirectUser(actionData);
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, []);

  return <></>;
};

export default ActionComponent;
