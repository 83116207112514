import { configureStore } from "@reduxjs/toolkit";

import app from "./application/app.slice";
import survey from "./survey/survey.slice";

export default configureStore({
  reducer: {
    app,
    survey,
  },
});
