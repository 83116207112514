import React from "react";
import { BrowserRouter } from "react-router-dom";

//providers
import { AuthProvider } from "./context/AuthContext";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

//styles
import "antd/dist/antd.min.css";
import "./assets/css/global.css";
import "./assets/css/ui.css";
import 'react-toastify/dist/ReactToastify.css';

//redux
import store from "./store";

//routes
import AppRoutes from "./routes";

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <ToastContainer
            position="top-center"
            closeOnClick
            pauseOnHover
            autoClose={2000}
          />
          <AppRoutes />
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
