import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//redux
import { errors } from "../../../../store/survey/survey.slice";
import { putResponse } from "../../../Survey/validates";

//styles
import "../../../../assets/css/survey/dropdown.css";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

//itens de pergunta
const DropdownLanguageComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const listError = useSelector(errors);
  const [dropdownValue, setDropdownValue] = useState("");

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);
    if (surveyData.user.responses[data.id]) {
      setDropdownValue(surveyData.user.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  const setDropdownResponse = (languageValue) => {
    let surveyData = getStorage(surveyid, true);
    if (languageValue === "") {
      delete surveyData.user.responses[data.id];
    } else {
      surveyData.survey.language = languageValue;
      surveyData.user.responses[data.id] = languageValue;
      surveyData.user.url.__language = languageValue;
    }
    setStorage(surveyid, surveyData, true);
    setDropdownValue(languageValue);
    putResponse(surveyid);
  };

  return (
    <div id="dropdown-component" className={`dropdown ${data?.cssClass || ""}`}>
      <div className="dropdown-title">
        <Interweave content={data.title[language]} />{" "}
        {data.require && data.properties?.showAsterisk && (
          <span className="require">
            <sup>*</sup>
          </span>
        )}
      </div>
      <div className="dropdown-content">
        <select
          className="select"
          value={dropdownValue}
          onChange={({ target: { value } }) => setDropdownResponse(value)}
        >
          <option value="" defaultValue>
            ...
          </option>
          {data.options &&
            data.options.map((opt, optIndex) => (
              <option
                key={`opt-${data.id}-${opt.value}-${optIndex}`}
                value={opt.value}
              >
                {opt.label}
              </option>
            ))}
        </select>
      </div>
      <div className="dropdown-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default DropdownLanguageComponent;
