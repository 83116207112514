import React, { useState, useEffect } from "react";
import { Interweave } from "interweave";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//redux
import { errors } from "../../../../store/survey/survey.slice";
import { checkIfUpdate } from "../../../../store/survey/survey.actions";
import { putResponse } from "../../../Survey/validates";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

//styles
import "../../../../assets/css/survey/optin.css";

const OptinComponent = ({ data }) => {
  const listError = useSelector(errors);
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const dispatch = useDispatch();
  const [optinValue, setOptinValue] = useState(false);

  const markOptin = () => {
    let surveyData = getStorage(surveyid, true);

    const optValue = optinValue === "true" ? "false" : "true";

    surveyData.user.responses[data.id] = optValue;
    setStorage(surveyid, surveyData, true);
    setOptinValue(optValue);
    putResponse(surveyid);
    dispatch(checkIfUpdate(surveyid, data.id));
  };

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);
    if (surveyData.user.responses[data.id]) {
      setOptinValue(surveyData.user.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div id="optin-component" className={`${data?.cssClass || ""}`}>
      <div className="optin-content">
        <input
          type="checkbox"
          id={`optin-${data.id}`}
          name={`optin-${data.id}`}
          className="optin-item"
          checked={optinValue === "true"}
          onChange={() => markOptin()}
        />
        <label htmlFor={`optin-${data.id}`}>
          <Interweave content={data.title[language]} />
        </label>
      </div>
      <div className="optin-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default OptinComponent;
