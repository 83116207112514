import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "app",
  initialState: {
    loadingRedux: false,
    noticeRedux: null,
    headerRedux: true,
    footerRedux: false,
  },
  reducers: {
    setLoading(state, { payload }) {
      state.loadingRedux = payload;
    },
    setNotice(state, { payload }) {
      state.noticeRedux = payload;
    },
    setHeaderRedux(state, { payload }) {
      state.headerRedux = payload;
    },
    setFooterRedux(state, { payload }) {
      state.footerRedux = payload;
    },
  },
});

export const { setLoading, setNotice, setHeaderRedux, setFooterRedux } =
  slice.actions;

export const loadingRedux = (state) => state.app.loadingRedux;
export const noticeRedux = (state) => state.app.noticeRedux;
export const headerRedux = (state) => state.app.headerRedux;
export const footerRedux = (state) => state.app.footerRedux;

export default slice.reducer;
