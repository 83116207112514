import React from "react";
//styles
import "../../../assets/css/general/home.survey.css";

//images
import LogoInovyoSurvey from "../../../assets/images/inovyo-survey.svg";

const HomeSurvey = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div id="home-survey">
      <div className="content">
        <div className="logo">
          <img src={LogoInovyoSurvey} alt="Logo Inovyo Survey" />
        </div>
        <div className="message">
          Um aplicativo Inovyo <span>&hearts;</span>
        </div>
        <div className="footer">
          Inovyo Survey<sup>&copy;</sup> 2022 - {currentYear}
        </div>
      </div>
    </div>
  );
};

export default HomeSurvey;
