import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

//styles
import "../../../../../assets/css/survey/checkbox.css";

//utils
import { getStorage, setStorage } from "../../../../../utils/Storage";

//redux
import { errors } from "../../../../../store/survey/survey.slice";

const CheckboxInputComponent = ({ idStorage, radioItem, questionId }) => {
  const { surveyid } = useParams();
  const [inputValue, setInputValue] = useState("");
  const listError = useSelector(errors);

  const setInputText = (value) => {
    let surveyData = getStorage(surveyid, true);
    let newAux = surveyData.user.responses[questionId].map((item) => {
      if (item.indexOf(radioItem.value) >= 0) {
        let splitItem = item.split("||");
        splitItem[1] = value;
        surveyData.inputs[idStorage] = value;
        item = splitItem.join("||");
      }
      return item;
    });
    surveyData.user.responses[questionId] = newAux;
    setStorage(surveyid, surveyData, true);
    setInputValue(value);
  };

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);
    let val = surveyData.inputs[idStorage];

    const foundResponseInput = surveyData.user.responses[questionId].filter(
      (element) => element.indexOf(radioItem.value) >= 0
    );

    if (val === undefined && foundResponseInput) {
      val = foundResponseInput[0].split("||")[1];
    }

    if (val && surveyData.user.responses[questionId] && foundResponseInput) {
      setInputValue(val);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="box-properties">
      <input
        type="text"
        style={{
          border: `solid ${
            radioItem.properties?.require && listError?.[radioItem.id]
              ? "2px red"
              : "1px #ccc"
          }`,
        }}
        value={inputValue}
        placeholder={radioItem.properties?.placeholder || "Escreva.."}
        onChange={({ target: { value } }) => setInputText(value)}
      />
      <div className="other-error">{listError?.[radioItem.id] || ""}</div>
    </div>
  );
};

export default CheckboxInputComponent;
