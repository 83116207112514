import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//styles
import "../../../../assets/css/survey/cascading-dropdown.css";

//redux
import { errors } from "../../../../store/survey/survey.slice";
import { checkIfUpdate } from "../../../../store/survey/survey.actions";
import { putResponse } from "../../../Survey/validates";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

const CascadingDropdown = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const dispatch = useDispatch();
  const listError = useSelector(errors);
  const [cdValue, setCDValue] = useState([]);

  let drops = {};
  const items = JSON.parse(data.options.items);

  const GenerateOptions = (options) => {
    for (var item in options) {
      drops[item] = options[item];
      if (Object.keys(options[item]).length > 0) {
        GenerateOptions(options[item]);
      }
    }
  };

  GenerateOptions(items);

  const resetMyOptions = (currentPosition) => {
    let surveyData = getStorage(surveyid, true);
    if (surveyData.user.responses[data.id]) {
      let auxMyOptions = [...surveyData.user.responses[data.id]];
      auxMyOptions.splice(
        currentPosition,
        surveyData.user.responses[data.id].length
      );
      return auxMyOptions;
    } else {
      return [];
    }
  };

  const setCascadingDropdownResponse = (optionSelected) => {
    let surveyData = getStorage(surveyid, true);

    if (optionSelected.length > 0) {
      surveyData.user.responses[data.id] = optionSelected;
    } else {
      delete surveyData.user.responses[data.id];
    }

    setStorage(surveyid, surveyData, true);
    setCDValue(optionSelected);
    putResponse(surveyid);
    dispatch(checkIfUpdate(surveyid, data.id));
  };

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);
    if (surveyData.user.responses[data.id]) {
      setCDValue(surveyData.user.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      id="cascading-dropdown-component"
      className={`cascading-dropdown ${data?.cssClass || ""}`}
    >
      <div className="cascading-dropdown-title">
        <Interweave content={data.title[language]} />{" "}
        {data.require && data.properties && data.properties.showAsterisk && (
          <span className="require">
            <sup>*</sup>
          </span>
        )}
      </div>
      <div className="cascading-dropdown-content">
        <span className="label">{data.options.titles[language][0]}</span>
        <select
          className="select"
          value={cdValue && cdValue[0]}
          onChange={({ target: { value } }) => {
            if (value === "") {
              setCascadingDropdownResponse([]);
            } else {
              let aux = resetMyOptions(0);
              aux[0] = value;
              setCascadingDropdownResponse(aux);
            }
          }}
        >
          <option value="" defaultValue>
            Escolha uma opção...
          </option>
          {Object.keys(items).map((opt, optIndex) => {
            return (
              <option key={`opt-${data.id}-${optIndex}`} value={opt}>
                {opt}
              </option>
            );
          })}
        </select>

        {cdValue &&
          cdValue.map((value, index) => {
            return (
              Object.keys(drops[value]).length > 0 && (
                <React.Fragment key={`select-${value}-${index}`}>
                  <span className="label">
                    {data.options.titles[language][index + 1]}
                  </span>
                  <select
                    className="select"
                    value={cdValue[index + 1]}
                    onChange={({ target: { value: internValue } }) => {
                      let aux = resetMyOptions(index + 1);
                      if (internValue === "") {
                        setCascadingDropdownResponse(aux);
                      } else {
                        aux[index + 1] = internValue;
                        setCascadingDropdownResponse(aux);
                      }
                    }}
                  >
                    <option value="" defaultValue>
                      Escolha uma opção...
                    </option>
                    {Object.keys(drops[value]).map((opt, optIndex) => {
                      return (
                        <option
                          key={`opt-${data.id}-${value}-${optIndex}`}
                          value={opt}
                        >
                          {opt}
                        </option>
                      );
                    })}
                  </select>
                </React.Fragment>
              )
            );
          })}
      </div>
      <div className="cascading-dropdown-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};
export default CascadingDropdown;
