import React, { useEffect, useState } from "react";
import { Interweave } from "interweave";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { BsFillCameraFill } from "react-icons/bs";
import { toast } from "react-toastify";
import api from "../../../../services/api";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

//styles
import "../../../../assets/css/survey/image-upload.css";

//redux
import { errors, userResponses } from "../../../../store/survey/survey.slice";
import { checkIfUpdate } from "../../../../store/survey/survey.actions";

import { putResponse } from "../../../Survey/validates";

const ImageUpload = ({ data }) => {
  const listError = useSelector(errors);
  const userData = useSelector(userResponses);
  const { surveyid } = useParams();
  const dispatch = useDispatch();

  let {
    survey: { language },
  } = getStorage(surveyid, true);
  const [myFiles, setMyFiles] = useState([]); //salva o blob puro
  const [photos, setPhotos] = useState([]); // salva o base64 para renderizar imagems
  const [uploading, setUploading] = useState(false); //inofrma que está em processo de upload
  const [renderImage, setRenderImage] = useState(""); //

  var count = 0;
  var readered = [];
  var fileErrors = 0;
  let hostname = window.location.hostname;

  let bucketS3 =
    hostname.indexOf("localhost") >= 0 || hostname.indexOf("devsurvey") >= 0
      ? "https://ism-dev-qngp98mp-bucket.s3.amazonaws.com/" //test
      : "https://inovyosurvey-bucket.s3.amazonaws.com/"; //production

  const getBase64 = () => {
    if (count < myFiles.length) {
      let base64load = "";
      var reader = new FileReader();
      reader.readAsDataURL(myFiles[count]);
      reader.onload = () => {
        base64load = reader.result;
      };

      setTimeout(async () => {
        await api
          .post("/image-file", {
            surveyid,
            responseid: userData.id,
            questionid: data.id,
            image: base64load,
          })
          .then((result) => {
            const { status, image } = result.data.body;
            if (status) {
              readered.push(bucketS3 + image);
            }
            count++;
            getBase64();
          })
          .catch(() => {
            fileErrors++;
          });
      }, 500);
    } else {
      let surveyData = getStorage(surveyid, true);
      surveyData.user.responses[data.id] = [...photos, ...readered];
      setStorage(surveyid, surveyData, true);
      setPhotos([...photos, ...readered]);
      count = 0;
      readered = [];
      setUploading(false);
      setMyFiles([]);
      if (fileErrors > 0) {
        toast.error(fileErrors + " arquivos falharam no envio.");
      }
      fileErrors = 0;
      putResponse(surveyid);
      dispatch(checkIfUpdate(surveyid, data.id));
    }
  };

  const chosenFiles = ({ target: { files } }) => {
    const { acceptTypes, maxSize, limit } = data.properties;
    setUploading(true);
    let hasError = null;
    let extractImages = [];

    if (photos.length + files.length > limit) {
      hasError = `Permitido envio de apenas ${limit} arquivos. (Only ${limit} files allowed.)`;
    }

    for (const image of Object.values(files)) {
      //analisa fotos apenas quando não houver algum erro nelas
      if (hasError === null) {
        const calcImageSize = parseInt(image.size <= 0 ? 0 : image.size / 1000);
        if (!acceptTypes.includes(image.type)) {
          hasError =
            "Algum arquivo possui um formato não permitido. (Some file has a disallowed format.)";
        } else if (calcImageSize > maxSize) {
          const limitSize = parseInt(maxSize / 1000);
          hasError = `Permitido envio de aquivo até ${limitSize}mb. (Allowed file upload up to ${limitSize}mb.)`;
        } else {
          extractImages.push(image);
        }
      }
    }

    if (hasError !== null) {
      setUploading(false);
      toast.error(hasError);
    } else {
      setMyFiles([...myFiles, ...extractImages]);
    }
    document.getElementById(`inovyo-image-file${data.id}`).value = null;
  };

  const deleteImage = async (indexImage) => {
    await api
      .delete(`/file?fid=${photos[indexImage]}`)
      .then(() => {
        let photosCopy = [...photos];
        photosCopy.splice(indexImage, 1);
        setPhotos(photosCopy);
        let surveyData = getStorage(surveyid, true);

        if (photosCopy.length > 0) {
          surveyData.user.responses[data.id] = photosCopy;
        } else {
          delete surveyData.user.responses[data.id];
        }

        setStorage(surveyid, surveyData, true);

        putResponse(surveyid);
        dispatch(checkIfUpdate(surveyid, data.id));
      })
      .catch(() => {
        toast.error("Falha ao excluir arquivo. (Fail to delete image)");
      });
  };

  useEffect(() => {
    myFiles.length > 0 && getBase64();
    // eslint-disable-next-line
  }, [myFiles]);

  const whatQuestionPage = (pages) => {
    let current = 0;
    pages.forEach((p, pageIndex) => {
      p.questions.forEach((q) => {
        if (q.id === data.id) {
          current = pageIndex;
        }
      });
    });
    return current;
  };

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);
    if (
      surveyData.user.responses[data.id] &&
      whatQuestionPage(surveyData.survey.pages) === surveyData.user.currentPage
    ) {
      setPhotos(surveyData.user.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      id="image-upload-component"
      className={`image-upload ${data?.cssClass || ""}`}
    >
      <div className="image-upload-title">
        <Interweave content={data.title[language]} />
        {data.require && data.properties && data.properties.showAsterisk && (
          <span className="require">
            <sup>*</sup>
          </span>
        )}
      </div>
      <div className="image-upload-content">
        {photos.length === 0 && (
          <label htmlFor={`inovyo-image-file${data.id}`}>
            <BsFillCameraFill />
          </label>
        )}
        <input
          type="file"
          multiple="multiple"
          id={`inovyo-image-file${data.id}`}
          className="image-upload-file"
          onChange={(e) => chosenFiles(e)}
        />
        {photos.length > 0 && (
          <div className="image-upload-box">
            {photos.map((img64, index) => {
              return (
                <div key={`img64-${index}-${data.id}`} className="slot">
                  <img
                    src={img64}
                    alt={`img64-${index}-${data.id}`}
                    width="100%"
                    onClick={() => setRenderImage(img64)}
                  />
                  <div className="btn-close" onClick={() => deleteImage(index)}>
                    x
                  </div>
                </div>
              );
            })}
            <label
              htmlFor={`inovyo-image-file${data.id}`}
              className="add-image"
              title="Adicionar mais imagens"
            >
              +
            </label>
          </div>
        )}
      </div>
      <div className="image-upload-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
      {uploading && (
        <div className="image-upload-mask">Carregando arquivos...</div>
      )}
      {renderImage !== "" && (
        <>
          <div className="image-upload-mask" onClick={() => setRenderImage("")}>
            <img src={renderImage} alt="Imagem carregada" />
            <div className="btn-close-mask" onClick={() => setRenderImage("")}>
              x
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageUpload;
