import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//redux
import { errors } from "../../../../store/survey/survey.slice";
import { checkIfUpdate } from "../../../../store/survey/survey.actions";
import { putResponse } from "../../../Survey/validates";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

//styles
import "../../../../assets/css/survey/csat.css";

const CSATComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const dispatch = useDispatch();
  const listError = useSelector(errors);
  const [csatValue, setCSATValue] = useState(null);

  const setCSATResponse = (num) => {
    let surveyData = getStorage(surveyid, true);
    surveyData.user.responses[data.id] = num;
    setStorage(surveyid, surveyData, true);
    setCSATValue(num);
    putResponse(surveyid);
    dispatch(checkIfUpdate(surveyid, data.id));
  };

  const checkTypeSelectdItem = (key) => csatValue === key;

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);
    if (surveyData.user.responses[data.id]) {
      setCSATValue(surveyData.user.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  let designCustom = [];

  //aplicando bordas redondas
  if (data.properties?.rounded) designCustom.push("rounded");

  //aplicando sombra
  if (data.properties?.shadow) designCustom.push("shadow");

  //aplicando colorido
  if (data.properties?.colorfull) designCustom.push("colorfull");

  //aplicando emoticon básicos
  if (data.properties?.emoticon) designCustom.push("emoticon");

  //aplicando emoticon colorido
  if (data.properties?.emoticonColorfull)
    designCustom.push("emoticon-colorfull");

  //aplicando emoticon 3D
  if (data.properties?.emoticon3D) designCustom.push("emoticon-3d");

  //aplicando estrelas
  if (data.properties?.stars) designCustom.push("stars");

  //aplicando estrelas 3D
  if (data.properties?.stars3D) designCustom.push("stars-3d");

  //aplicando estrelas 3D animadas
  if (data.properties?.stars3DAnimated) designCustom.push("stars-3d-animated");

  //aplicando csat com 3 rostinhos
  if (data.properties?.faces3) designCustom.push("faces-3");

  //aplicando like - deslike
  if (data.properties?.likeDeslike) designCustom.push("like-dislike");

  //aplicando ama não ama
  if (data.properties?.loveDislove) designCustom.push("love-dislove");

  //aplicando padrão antigo
  if (data.properties?.standard) designCustom.push("standard");

  const styleCsat =
    data.properties && data.properties.baseColor
      ? `
      div.csat-${data.id} .csat-content .csat-item {
        border: solid 1px ${data.properties.baseColor} !important;
      }
      div.csat-${data.id} .csat-content .csat-item:hover {
        background-color: ${data.properties.baseColor}aa !important;
        color: #fff !important;
      }
      div.csat-${data.id} .csat-content .csat-item.selected {
        background-color: ${data.properties.baseColor} !important;
        color: #fff !important;
      }
      `
      : ``;

  const CSAT = () => {
    const hideText = () => {
      if (
        designCustom.includes("emoticon") ||
        designCustom.includes("emoticon-colorfull") ||
        designCustom.includes("emoticon-3d") ||
        designCustom.includes("stars") ||
        designCustom.includes("stars-3d") ||
        designCustom.includes("stars-3d-animated") ||
        designCustom.includes("faces-3") ||
        designCustom.includes("like-dislike") ||
        designCustom.includes("love-dislove")
      ) {
        return true;
      }

      return false;
    };

    const selectedOrNot = (selectedValue, num) => {
      //Se for estrelas
      if (
        designCustom.includes("stars") ||
        designCustom.includes("stars-3d") ||
        designCustom.includes("stars-3d-animated")
      ) {
        return selectedValue >= parseInt(num) && selectedValue !== "na";
      } else {
        return selectedValue === num;
      }
    };

    return (
      <>
        {data.properties?.naOptionLeft && (
          <div
            key={`csat-options-${data.id}-na`}
            className={`csat-item ${
              csatValue === "na" ? "selected" : ""
            } option-color-0 na-enable`}
            alt={"na"}
            onClick={() => setCSATResponse("na")}
          >
            {!hideText() && "N/A"}
          </div>
        )}
        {data.options &&
          data.options.map((num, i) => {
            return (
              <div
                key={`csat-options-${data.id}-${i}`}
                className={`csat-item ${
                  selectedOrNot(csatValue, num) ? "selected" : ""
                } ${`option-color-${num}`} ${
                  data.properties && data.properties.naOption ? "na-enable" : ""
                }`}
                alt={num}
                onClick={() => setCSATResponse(num)}
              >
                {!hideText() && num}
              </div>
            );
          })}
        {data.properties?.naOptionRight && (
          <div
            key={`csat-options-${data.id}-na`}
            className={`csat-item ${
              csatValue === "na" ? "selected" : ""
            } option-color-0 na-enable`}
            alt={"na"}
            onClick={() => setCSATResponse("na")}
          >
            {!hideText() && "N/A"}
          </div>
        )}
      </>
    );
  };

  const CSAT_STANDARD = () => {
    return (
      <>
        <div className="numbers">
          {data.properties?.naOptionLeft && (
            <span key={`csat-options-label-na-${data.id}`}>N/A</span>
          )}
          {data.options &&
            data.options.map((num, i) => {
              return (
                <span key={`csat-options-label-${i}-${data.id}`}>{num}</span>
              );
            })}
          {data.properties?.naOptionRight && (
            <span key={`csat-options-label-na-${data.id}`}>N/A</span>
          )}
        </div>
        <div className="radio">
          {data.properties?.naOptionLeft && (
            <span key={`csat-options-na-${data.id}`}>
              <input
                type="radio"
                name={`csat-options-${data.id}`}
                id={`csat-options-na-${data.id}`}
                value="na"
                checked={checkTypeSelectdItem("na")}
                onChange={() => setCSATResponse("na")}
              />
              <label htmlFor={`csat-options-na-${data.id}`}></label>
            </span>
          )}
          {data.options &&
            data.options.map((num, i) => {
              return (
                <span key={`csat-options-${i}-${data.id}`}>
                  <input
                    type="radio"
                    name={`csat-options-${data.id}`}
                    id={`csat-options-${i}-${data.id}`}
                    value={num}
                    checked={checkTypeSelectdItem(num)}
                    onChange={() => setCSATResponse(num)}
                  />
                  <label htmlFor={`csat-options-${i}-${data.id}`}></label>
                </span>
              );
            })}
          {data.properties?.naOptionRight && (
            <span key={`csat-options-na-${data.id}`}>
              <input
                type="radio"
                name={`csat-options-${data.id}`}
                id={`csat-options-na-${data.id}`}
                value="na"
                checked={checkTypeSelectdItem("na")}
                onChange={() => setCSATResponse("na")}
              />
              <label htmlFor={`csat-options-na-${data.id}`}></label>
            </span>
          )}
        </div>
      </>
    );
  };

  const GenerateCSAT = () => {
    if (designCustom.includes("standard")) {
      return (
        <div className="csat-content-standard">
          <CSAT_STANDARD />
        </div>
      );
    } else {
      return (
        <div className="csat-content">
          <CSAT />
        </div>
      );
    }
  };

  return (
    <div>
      <div
        id="csat-component"
        className={`csat csat-${data.id} ${designCustom.join(" ")} ${
          data?.cssClass || ""
        }`}
      >
        <div className="csat-question">
          <Interweave content={data.title[language]} />
        </div>
        <GenerateCSAT />
        <div className="csat-error">
          {Object.keys(listError).includes(data.id + "")
            ? listError[data.id]
            : ""}
        </div>
      </div>
      {styleCsat !== "" && <style>{styleCsat}</style>}
    </div>
  );
};

export default CSATComponent;
