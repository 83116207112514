import React, { useState, useEffect } from "react";
import { Interweave } from "interweave";
import { useSelector, useDispatch } from "react-redux";
import { BsCheckLg } from "react-icons/bs";
import { useParams } from "react-router-dom";

//redux
import { errors } from "../../../../store/survey/survey.slice";
import { checkIfUpdate } from "../../../../store/survey/survey.actions";
import { putResponse } from "../../../Survey/validates";

//styles
import "../../../../assets/css/survey/ces.css";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

const CESComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const dispatch = useDispatch();
  const listError = useSelector(errors);
  const [cesValue, setCESTValue] = useState(null);

  const setCESResponse = (num) => {
    let surveyData = getStorage(surveyid, true);
    surveyData.user.responses[data.id] = num;
    setStorage(surveyid, surveyData, true);
    setCESTValue(num);
    putResponse(surveyid);
    dispatch(checkIfUpdate(surveyid, data.id));
  };

  useEffect(() => {
    const surveyData = getStorage(surveyid, true);
    if (surveyData.user.responses[data.id]) {
      setCESTValue(surveyData.user.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  const CES = () => (
    <>
      {data.options &&
        data.options.map((opt, i) => {
          return (
            <div
              key={`ces-options-${data.id}-${i}`}
              className={`ces-item ${
                cesValue === opt.value ? "selected" : ""
              } ${`option-color-${opt.value}`}`}
              alt={opt.label}
              onClick={() => setCESResponse(opt.value)}
            >
              {opt.label[language]}
            </div>
          );
        })}
      {data.properties && data.properties.naOption && (
        <div
          key={`ces-options-0`}
          className={`ces-item ${
            cesValue === "na" ? "selected" : ""
          } ${`option-color-0`}`}
          alt={"na"}
          onClick={() => setCESResponse("na")}
        >
          Não se aplica
        </div>
      )}
    </>
  );

  const CESEmoticon = () => {};

  const CESEffort = () => (
    <div className="ces-effort-box">
      {data.options &&
        data.options.map((num, i) => {
          return (
            <div
              key={`ces-options-${data.id}-${i}`}
              className={`ces-item ${
                cesValue === num ? "selected" : ""
              } ${`ces-color-${num}`}`}
              alt={num}
              onClick={() => setCESResponse(num)}
            >
              <div className="number">{num}</div>
              {cesValue === num && <BsCheckLg />}
            </div>
          );
        })}
      <div className="ces-effort-text">
        <span className="left">Muito Difícil</span>
        <span className="right">Muito Fácil</span>
      </div>
    </div>
  );

  let design = "ces";
  if (data.properties && data.properties.design) {
    design =
      {
        emoticon: "ces-emoticon",
        effort: "ces-effort",
      }[data.properties.design] || "ces";
  }

  const GenerateCES = () => {
    switch (design) {
      case "ces":
        return <CES />;
      case "ces-emoticon":
        return <CESEmoticon />;
      case "ces-effort":
        return <CESEffort />;
      default:
        return <></>;
    }
  };

  return (
    <div id="ces-component" className={`${design} ${data?.cssClass || ""}`}>
      <div className="ces-title">
        <Interweave content={data.title[language]} />
        {data.require && data.properties && data.properties.showAsterisk && (
          <span className="require">
            <sup>*</sup>
          </span>
        )}
      </div>
      <div className="ces-content">
        <GenerateCES />
      </div>
      <div className="ces-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default CESComponent;
