import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//redux
import { errors } from "../../../../store/survey/survey.slice";
import { checkIfUpdate } from "../../../../store/survey/survey.actions";
import { putResponse } from "../../../Survey/validates";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

//styles
import "../../../../assets/css/survey/radiobox.css";

//compoenent
import RadioboxInput from "./RadioboxInput";

const RadioboxComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const dispatch = useDispatch();
  const listError = useSelector(errors);
  const [radioboxValue, setRadioboxValue] = useState("");

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);

    if (surveyData.user.responses[data.id]) {
      setRadioboxValue(surveyData.user.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  let designType = () => {
    let classDesign = "radio";

    if (data.properties?.check) {
      classDesign = "check";
    } else if (data.properties?.pill) {
      classDesign = "pill";
    } else if (data.properties?.pillCheck) {
      classDesign = "pill-check";
    } else if (data.properties?.full) {
      classDesign = "full";
    }

    return classDesign;
  };

  let designCustom = () => {
    let designCustom = [];

    if (data.properties?.shadow) {
      designCustom.push("shadow");
    }

    return designCustom.join(" ");
  };

  const selectRadiobox = (radioboxNumber, properties) => {
    let surveyData = getStorage(surveyid, true);

    if (properties) {
      radioboxNumber = radioboxNumber + "||";
    }

    surveyData.user.responses[data.id] = radioboxNumber;
    setStorage(surveyid, surveyData, true);
    setRadioboxValue(radioboxNumber);
    putResponse(surveyid);
    dispatch(checkIfUpdate(surveyid, data.id));
  };

  const checkTypeSelectdItem = (key) => {
    if (radioboxValue === key || radioboxValue.indexOf(key) >= 0) {
      return true;
    }
    return false;
  };

  const RadioboxDefault = () => {
    return (
      <>
        {data.options &&
          data.options.map((item, i) => {
            return (
              <div
                key={`radiobox-option-${data.id}-${i}`}
                className="radiobox-option"
              >
                <input
                  type="radio"
                  id={`radiobox-${data.id}-${i}`}
                  name={`radiobox-${data.id}-${i}`}
                  className="radiobox-item"
                  value={item.value}
                  checked={checkTypeSelectdItem(item.value)}
                  onChange={() => selectRadiobox(item.value, item.properties)}
                />
                <label
                  htmlFor={`radiobox-${data.id}-${i}`}
                  onClick={() => selectRadiobox(item.value, item.properties)}
                >
                  {item.label[language]}
                </label>
                {item?.properties &&
                  item.properties?.type === "other" &&
                  checkTypeSelectdItem(item.value) && (
                    <RadioboxInput
                      questionId={data.id}
                      radioItem={item}
                      idStorage={`${data.id}-${i}-0`}
                    />
                  )}
              </div>
            );
          })}
      </>
    );
  };

  const RadioboxCustom = () => {
    return (
      <>
        {data.options &&
          data.options.map((item, i) => {
            return (
              <React.Fragment key={`radiobox-option-${data.id}-${i}`}>
                <div
                  className={`radiobox-option ${
                    checkTypeSelectdItem(item.value) ? "selected" : ""
                  }`}
                  onClick={() => selectRadiobox(item.value, item.properties)}
                >
                  <span>{item.label[language]}</span>
                </div>
                {item?.properties &&
                  item.properties?.type === "other" &&
                  checkTypeSelectdItem(item.value) && (
                    <RadioboxInput
                      questionId={data.id}
                      radioItem={item}
                      idStorage={`${data.id}-${i}-0`}
                    />
                  )}
              </React.Fragment>
            );
          })}
      </>
    );
  };

  const GenerateRadiobox = () => {
    switch (designType()) {
      case "radio":
      case "check":
      case "pill":
      case "pill-check":
        return <RadioboxDefault />;
      case "full":
        return <RadioboxCustom />;
      default:
        return <></>;
    }
  };

  return (
    <div
      id="radiobox-component"
      className={`${designType()} ${designCustom()} ${data?.cssClass || ""}`}
    >
      <div className="radiobox-title">
        <Interweave content={data.title[language]} />
      </div>
      <div className="radiobox-content">
        <GenerateRadiobox />
      </div>
      <div className="radiobox-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default RadioboxComponent;
