/**
 * Cria um storage se ele não existir. O padrão é vazio, mas
 * ele pode receber um valor padrão desde que seja informado
 * @param {string} name - nome do storage
 * @param {string} value - informação a ser armazenada
 * @param {boolean} isJson - se verdadeiro, transforma um objeto em string
 */
export const createStorageIfNotExist = (name, value, isJson = false) => {
  const storeFilter = localStorage.getItem(name);
  if (storeFilter === null) {
    localStorage.setItem(
      name,
      value !== "" ? (isJson ? JSON.stringify(value) : value) : ""
    );
  }
};

/**
 * Cria um storage
 * @param {string} name - nome do storage
 * @param {string} value - informação a ser armazenada
 * @param {boolean} isJson - se verdadeiro, transforma um objeto em string
 */
export const setStorage = (name, value, isJson = false) => {
  localStorage.setItem(name, isJson ? JSON.stringify(value) : value);
};

/**
 * Pega o valor de um storage e retorna se ele existir
 * @param {string} name - nome do storage
 * @param {boolean} isJson - se verdadeiro, transforma uma string em objeto
 * @returns {string|object}
 */
export const getStorage = (name, isJson = false) => {
  const storage = localStorage.getItem(name);
  return storage !== "" && storage !== null
    ? isJson
      ? JSON.parse(storage)
      : storage
    : "";
};

/**
 * Exclui um storage específico
 * @param {string} name
 */
export const deleteStorage = (name) => {
  localStorage.removeItem(name);
};

/**
 * Apaga todos os storages do navegador
 */
export const clearStorages = () => {
  localStorage.clear();
};
