import axios from "axios";

let hostname = window.location.hostname;

let baseURL =
  hostname.indexOf("localhost") >= 0 || hostname.indexOf("devsurvey") >= 0
    ? "https://hbgwk8p3pi.execute-api.us-east-1.amazonaws.com/v1" //test
    : "https://1nbwgvslo1.execute-api.us-east-1.amazonaws.com/v1"; //production

export default axios.create({ baseURL });
