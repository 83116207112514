import React from "react";

import { Layout } from "antd";

//components
import ContentApp from "./ContentApp";

const LayoutApp = (props) => {
  const { children } = props;

  return (
    <Layout>
      <ContentApp>{children}</ContentApp>
    </Layout>
  );
};

export default LayoutApp;
