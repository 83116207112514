import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

//styles
import "../../../../../assets/css/survey/radiobox.css";

//utils
import { getStorage, setStorage } from "../../../../../utils/Storage";

//redux
import { errors } from "../../../../../store/survey/survey.slice";

const RadioboxInputComponent = ({ idStorage, questionId, radioItem }) => {
  const { surveyid } = useParams();
  const [inputValue, setInputValue] = useState("");
  const listError = useSelector(errors);

  const setInputText = (value) => {
    let surveyData = getStorage(surveyid, true);

    let question = surveyData.user.responses[questionId];

    if (question && question.indexOf(radioItem.value) >= 0) {
      let splitItem = question.split("||");
      splitItem[1] = value;
      surveyData.inputs[idStorage] = value;
      question = splitItem.join("||");
    }

    surveyData.user.responses[questionId] = question;
    setStorage(surveyid, surveyData, true);
    setInputValue(value);
  };

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);
    let val = surveyData.inputs[idStorage];

    const foundResponseInput =
      surveyData.user.responses?.[questionId] &&
      surveyData.user.responses[questionId].indexOf(radioItem.value) >= 0
        ? surveyData.user.responses[questionId]
        : null;

    if (val === undefined && foundResponseInput) {
      val = foundResponseInput.split("||")[1];
    }

    if (val && surveyData.user.responses[questionId] && foundResponseInput) {
      setInputValue(val);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="box-properties">
      <input
        type="text"
        style={{
          border: `solid ${
            radioItem.properties?.require && listError?.[radioItem.id]
              ? "2px red"
              : "1px #ccc"
          }`,
        }}
        value={inputValue}
        placeholder={radioItem.properties?.placeholder || "Escreva.."}
        onChange={({ target: { value } }) => setInputText(value)}
      />
      <div className="other-error">{listError?.[radioItem.id] || ""}</div>
    </div>
  );
};

export default RadioboxInputComponent;
