import React from "react";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//utils
import { getStorage } from "../../../../utils/Storage";

//styles
import "../../../../assets/css/survey/plain-text.css";

const PlainTextComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  return (
    <div id="plain-text-component" className={`${data?.cssClass || ""}`}>
      <div className="plain-text-content">
        <Interweave content={data.title[language]} />
      </div>
    </div>
  );
};

export default PlainTextComponent;
