import React, { useEffect, useState } from "react";
import { Interweave } from "interweave";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { BsFileEarmarkArrowUp } from "react-icons/bs";
import { toast } from "react-toastify";

import {
  BsFiletypeTxt,
  BsFiletypeDocx,
  BsFiletypeXlsx,
  BsFiletypePdf,
} from "react-icons/bs";

//api
import api from "../../../../services/api";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

//styles
import "../../../../assets/css/survey/file-upload.css";

//redux
import { errors, userResponses } from "../../../../store/survey/survey.slice";
import { checkIfUpdate } from "../../../../store/survey/survey.actions";

//components
import { putResponse } from "../../../Survey/validates";

const FileUpload = ({ data }) => {
  const listError = useSelector(errors);
  const userData = useSelector(userResponses);
  const { surveyid } = useParams();
  const dispatch = useDispatch();

  let {
    survey: { language },
  } = getStorage(surveyid, true);
  const [myFiles, setMyFiles] = useState([]); //salva o blob puro
  const [files, setFiles] = useState([]); // salva o base64 para renderizar imagems
  const [uploading, setUploading] = useState(false); //informa que está em processo de upload
  const [deleting, setDeleting] = useState(false); //informa que está em processo de exclusão
  const [renderImage, setRenderImage] = useState("");

  var count = 0;
  var readered = [];
  var fileErrors = 0;
  let hostname = window.location.hostname;
  const mimeTypes = [
    "application/pdf",
    "text/plain",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  let bucketS3 =
    hostname.indexOf("localhost") >= 0 || hostname.indexOf("devsurvey") >= 0
      ? "https://ism-dev-qngp98mp-bucket.s3.amazonaws.com/" //test
      : "https://inovyosurvey-bucket.s3.amazonaws.com/"; //production

  const getBase64 = () => {
    if (count < myFiles.length) {
      let base64load = "";
      var reader = new FileReader();
      reader.readAsDataURL(myFiles[count]);
      reader.onload = () => {
        base64load = reader.result;

        setTimeout(async () => {
          await api
            .post("/doc-file", {
              surveyid,
              responseid: userData.id,
              questionid: data.id,
              file: base64load,
              fileName: myFiles[count].name,
            })
            .then(({ data }) => {
              const { status, file } = data.body;
              if (status) {
                readered.push(bucketS3 + file);
              }
              count++;
              getBase64();
            })
            .catch(() => {
              fileErrors++;
            });
        }, 500);
      };
      reader.onerror = () => {};
    } else {
      const surveyData = getStorage(surveyid, true);
      surveyData.user.responses[data.id] = [...files, ...readered];
      setStorage(surveyid, surveyData, true);
      setFiles([...files, ...readered]);
      count = 0;
      readered = [];
      setUploading(false);
      setMyFiles([]);
      if (fileErrors > 0) {
        toast.error(fileErrors + " arquivos falharam no envio.");
      }
      fileErrors = 0;
      putResponse(surveyid);
      dispatch(checkIfUpdate(surveyid, data.id));
    }
  };

  const chosenFiles = ({ target: { files } }) => {
    const { acceptTypes, maxSize, limit } = data.properties;

    setUploading(true);
    let hasError = null;
    let extractFiles = [];

    if (files.length + files.length > limit) {
      hasError = `Permitido envio de apenas ${limit} arquivos. (Only ${limit} files allowed.)`;
    }

    const typeAccepted = (nm, ty) => {
      let foundedExtension = false;
      let foundedType = false;

      acceptTypes.forEach((a) => {
        if (nm.indexOf(`.${a}`) >= 0) {
          foundedExtension = true;
        }
      });

      if (mimeTypes.includes(ty)) {
        foundedType = true;
      }

      return foundedExtension && foundedType;
    };

    for (const doc of Object.values(files)) {
      //analisa documentos apenas quando não houver algum erro neles
      if (hasError === null) {
        const calcImageSize = parseInt(doc.size <= 0 ? 0 : doc.size / 1000);
        if (!typeAccepted(doc.name, doc.type)) {
          hasError =
            "Algum arquivo possui um formato não permitido. (Some file has a disallowed format.)";
        } else if (calcImageSize > maxSize) {
          const limitSize = parseInt(maxSize / 1000);
          hasError = `Permitido envio de aquivo até ${limitSize}mb. (Allowed file upload up to ${limitSize}mb.)`;
        } else {
          extractFiles.push(doc);
        }
      }
    }

    if (hasError !== null) {
      setUploading(false);
      toast.error(hasError);
    } else {
      setMyFiles([...myFiles, ...extractFiles]);
    }
    document.getElementById(`inovyo-post-file${data.id}`).value = null;
  };

  const deleteFile = async (indexFile) => {
    setDeleting(true);
    await api
      .delete(`/file?fid=${files[indexFile]}`)
      .then(() => {
        let filesCopy = [...files];
        filesCopy.splice(indexFile, 1);
        setFiles(filesCopy);
        let surveyData = getStorage(surveyid, true);

        if (filesCopy.length > 0) {
          surveyData.user.responses[data.id] = filesCopy;
        } else {
          delete surveyData.user.responses[data.id];
        }

        setStorage(surveyid, surveyData, true);

        putResponse(surveyid);
        dispatch(checkIfUpdate(surveyid, data.id));
      })
      .catch(() => {
        toast.error("Falha ao excluir arquivo. (Fail to delete image)");
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  useEffect(() => {
    myFiles.length > 0 && getBase64();
    // eslint-disable-next-line
  }, [myFiles]);

  const whatQuestionPage = (pages) => {
    let current = 0;
    pages.forEach((p, pageIndex) => {
      p.questions.forEach((q) => {
        if (q.id === data.id) {
          current = pageIndex;
        }
      });
    });
    return current;
  };

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);
    if (
      surveyData.user.responses[data.id] &&
      whatQuestionPage(surveyData.survey.pages) === surveyData.user.currentPage
    ) {
      setFiles(surveyData.user.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  const extractFileName = (urlFile) => {
    const urlFileSplit = urlFile.split("/");
    return urlFileSplit.pop();
  };

  const extractFileIcon = (name) => {
    if (name.indexOf(".txt") >= 0) {
      return <BsFiletypeTxt />;
    } else if (name.indexOf(".pdf") >= 0) {
      return <BsFiletypePdf />;
    } else if (name.indexOf(".xlsx") >= 0) {
      return <BsFiletypeXlsx />;
    } else if (name.indexOf(".docx") >= 0) {
      return <BsFiletypeDocx />;
    }

    return <BsFiletypeTxt />;
  };

  return (
    <div
      id="file-upload-component"
      className={`file-upload ${data?.cssClass || ""}`}
    >
      <div className="file-upload-title">
        <Interweave content={data.title[language]} />
      </div>
      <div className="file-upload-content">
        {files.length === 0 && (
          <label htmlFor={`inovyo-post-file${data.id}`}>
            <BsFileEarmarkArrowUp />
          </label>
        )}
        {files.length > 0 && (
          <div className="file-upload-box">
            {files.map((urlFile, index) => {
              const name = extractFileName(urlFile);
              const icon = extractFileIcon(name);

              return (
                <div key={`fileslot-${index}-${data.id}`} className="slot">
                  <div className="icon">{icon}</div>
                  <div className="name">{extractFileName(urlFile)}</div>
                  <div className="btn-close" onClick={() => deleteFile(index)}>
                    x
                  </div>
                </div>
              );
            })}
            <div className="slot-add">
              <label
                htmlFor={`inovyo-post-file${data.id}`}
                className="add-file"
                title="Adicionar mais arquivos"
              >
                + Adicionar
              </label>
            </div>
          </div>
        )}
        <input
          type="file"
          multiple="multiple"
          id={`inovyo-post-file${data.id}`}
          className="file-upload-file"
          accept={mimeTypes.join(", ")}
          onChange={(e) => chosenFiles(e)}
        />
      </div>
      <div className="file-upload-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
      {uploading && (
        <div className="file-upload-mask">Enviando arquivo(s)...</div>
      )}
      {deleting && <div className="file-upload-mask">Excluindo arquivo...</div>}
      {renderImage !== "" && (
        <>
          <div className="file-upload-mask" onClick={() => setRenderImage("")}>
            <img src={renderImage} alt="Arquivo carregado" />
            <div className="btn-close-mask" onClick={() => setRenderImage("")}>
              x
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FileUpload;
