import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

//utils
import { getStorage, deleteStorage } from "../../../../utils/Storage";
import { getLuminosity } from "../../../../utils/String";

//redux
import { setLoading } from "../../../../store/survey/survey.slice";

//styles
import "../../../../assets/css/survey/reset-button.css";

const ResetButtonComponent = ({ data }) => {
  const dispatch = useDispatch();
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const resetSurvey = () => {
    deleteStorage(surveyid);
    dispatch(setLoading(true));
    setTimeout(() => {
      window.location.href = `${window.location.href}`;
    }, 1000);
  };

  const { bgColor, align, block } = data.properties;

  let styleButton = {
    backgroundColor: bgColor ? bgColor : "#9a2376",
    color: bgColor
      ? getLuminosity(bgColor) === "dark"
        ? "#fff"
        : "#000"
      : "#fff",
    display: block ? "block" : "inline",
    width: block ? "100%" : "auto",
  };

  let styleContent = {
    textAlign: align ? align : "center",
  };

  return (
    <div id="reset-button-component" className={`${data?.cssClass || ""}`}>
      <div className="reset-button-content" style={styleContent}>
        <button onClick={() => resetSurvey()} style={styleButton}>
          {data.title[language]}
        </button>
      </div>
    </div>
  );
};

export default ResetButtonComponent;
