import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//redux
import { errors } from "../../../../store/survey/survey.slice";
import { checkIfUpdate } from "../../../../store/survey/survey.actions";
import { putResponse } from "../../../Survey/validates";

//styles
import "../../../../assets/css/survey/dropdown.css";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

//itens de pergunta
const DropdownComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const listError = useSelector(errors);
  const [dropdownValue, setDropdownValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    let surveyData = getStorage(surveyid, true);
    if (surveyData.user.responses[data.id]) {
      setDropdownValue(surveyData.user.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  const setDropdownResponse = (num) => {
    let surveyData = getStorage(surveyid, true);
    if (num === "") {
      delete surveyData.user.responses[data.id];
    } else {
      surveyData.user.responses[data.id] = num;
    }
    setStorage(surveyid, surveyData, true);
    setDropdownValue(num);
    putResponse(surveyid);
    dispatch(checkIfUpdate(surveyid, data.id));
  };

  return (
    <div id="dropdown-component" className={`dropdown ${data?.cssClass || ""}`}>
      <div className="dropdown-title">
        <Interweave content={data.title[language]} />{" "}
        {data.require && data.properties && data.properties.showAsterisk && (
          <span className="require">
            <sup>*</sup>
          </span>
        )}
      </div>
      <div className="dropdown-content">
        <select
          className="select"
          value={dropdownValue}
          onChange={({ target: { value } }) => setDropdownResponse(value)}
        >
          <option value="" defaultValue>
            Escolha uma opção...
          </option>
          {data.options &&
            data.options.map((opt, optIndex) => (
              <option
                key={`opt-${data.id}-${opt.value}-${optIndex}`}
                value={opt.value}
              >
                {opt.label[language]}
              </option>
            ))}
        </select>
      </div>
      <div className="dropdown-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default DropdownComponent;
