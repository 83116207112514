import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Interweave } from "interweave";
import { useParams } from "react-router-dom";

//redux
import { errors, userResponses } from "../../../../store/survey/survey.slice";

//styles
import "../../../../assets/css/survey/input.css";

//utils
import { getStorage, setStorage } from "../../../../utils/Storage";

const InputComponent = ({ data }) => {
  const { surveyid } = useParams();
  let {
    survey: { language },
  } = getStorage(surveyid, true);

  const listError = useSelector(errors);
  const userSurvey = useSelector(userResponses);
  const [localInput, setLocalInput] = useState("");

  const changeInput = (val) => {
    let surveyData = getStorage(surveyid, true);
    surveyData.user.responses[data.id] = val;
    setStorage(surveyid, surveyData, true);
    setLocalInput(val);
  };

  useEffect(() => {
    if (userSurvey.responses[data.id]) {
      setLocalInput(userSurvey.responses[data.id]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div id="input-component" className={`text ${data?.cssClass || ""}`}>
      <div className="input-question">
        <Interweave content={data.title[language]} />
      </div>
      <div className="input-content">
        <input
          type="text"
          maxLength={data?.properties?.maxLength || 250}
          value={localInput}
          onChange={({ target: { value } }) => changeInput(value)}
        />
      </div>
      <div className="input-error">
        {Object.keys(listError).includes(data.id + "")
          ? listError[data.id]
          : ""}
      </div>
    </div>
  );
};

export default InputComponent;
